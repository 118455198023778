
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    getColor() {
      if (this.color) {
        return this.color;
      }
      if (this.disabled) {
        return this.$whitelabel.lightTheme.textPrimary.lighten4;
      }
      return this.$whitelabel.lightTheme.buttonPrimary.lighten4;
    }
  }
});
