<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" :viewBox="`0 0 20 20`" fill="none">
    <path
      d="M15 15.7166H14.3667C13.7 15.7166 13.0667 15.975 12.6 16.4416L11.175 17.85C10.525 18.4916 9.46668 18.4916 8.81668 17.85L7.39166 16.4416C6.925 15.975 6.28333 15.7166 5.625 15.7166H5C3.61667 15.7166 2.5 14.6083 2.5 13.2417V4.14998C2.5 2.78331 3.61667 1.675 5 1.675H15C16.3833 1.675 17.5 2.78331 17.5 4.14998V13.2417C17.5 14.6 16.3833 15.7166 15 15.7166Z"
      :stroke="stroke" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9.99979 8.33334C11.0721 8.33334 11.9415 7.46401 11.9415 6.39166C11.9415 5.3193 11.0721 4.45003 9.99979 4.45003C8.92743 4.45003 8.05811 5.3193 8.05811 6.39166C8.05811 7.46401 8.92743 8.33334 9.99979 8.33334Z"
      :stroke="stroke" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.3332 13.05C13.3332 11.55 11.8415 10.3334 9.99984 10.3334C8.15817 10.3334 6.6665 11.55 6.6665 13.05"
      :stroke="stroke" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 20
    }
  },
  computed: {
    stroke() {
      return this.dark
        ? this.$whitelabel.lightTheme.backgroundPrimary.base
        : this.$whitelabel.lightTheme.backgroundPrimary.lighten5;
    }
  }
};
</script>