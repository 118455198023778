export default () => {
  if (process.client) {
    window.addEventListener('DOMContentLoaded', () => {
      document.addEventListener('focusin', (event) => {
        if (
          event.target.tagName === 'INPUT' ||
          event.target.tagName === 'TEXTAREA'
        ) {
          const viewport = document.querySelector('meta[name=viewport]');
          viewport.setAttribute(
            'content',
            'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
          );
        }
      });

      document.addEventListener('focusout', () => {
        // Reset the viewport after input is blurred
        const viewport = document.querySelector('meta[name=viewport]');
        viewport.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0'
        );
      });
    });
  }
};
