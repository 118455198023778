<template>
  <div v-if="show"
    class="toast-message"
    :class="{
      'toast-message--error': type === 'error',
      'toast-message--success': type === 'success',
      'toast-message--warning': type === 'warning'
    }">
    <div class="toast-message__icon">
      <IconSuccess v-if="type === 'success'"></IconSuccess>
      <IconError v-else-if="type === 'error'"></IconError>
      <IconWarning v-else-if="type === 'warning'"></IconWarning>
    </div>
    <div class="toast-message__content">
      <h3 class="toast-message__title">
        {{ header }}
      </h3>
      <p class="toast-message__message">
        {{ message }}
      </p>
    </div>
    <div class="toast-message__action">
      <IconCancel @click.native="show = false"></IconCancel>
    </div>
  </div>
</template>
<script>
import IconWarning from '../common/icons/Alert.vue';
import IconSuccess from './icons/IconSuccess.vue';
import IconError from './icons/IconError.vue';
import IconCancel from './icons/IconCancel.vue';
export default {
  name: 'BAlert',
  components: { IconSuccess, IconError, IconWarning, IconCancel },
  data() {
    return {
      timeout: 5000,
      type: 'success',
      show: false,
      title: '',
      message: '',
      color: ''
    };
  },
  computed: {
    header() {
      if (this.title) {
        return this.title;
      } else {
        switch (this.type) {
          case 'error':
            return this.$t('main.alert');
          case 'warning':
            return this.$t('main.warning');
          case 'success':
          default:
            return this.$t('main.success');
        }
      }
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.showNotification();
      }
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'alert/showMessage') {
        this.timeout = state.alert.timeout;
        this.title = state.alert.title;
        this.message = state.alert.content;
        this.type = state.alert.type;
        this.show = true;
      }
    });
  },
  methods: {
    showNotification() {
      setTimeout(() => {this.show = false}, this.timeout);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/_scss/_utils/variables.scss';
@import '~@/assets/_scss/_utils/mixins.scss';
.toast-message {
  z-index: 1001;
  position: fixed;
  bottom: 64px;
  right: 128px;
  display: grid;
  grid-template-columns: 42px auto 20px;
  gap: 16px;
  padding: 16px;
  width: 348px;
  height: 100px;
  border-radius: 8px;
  background: linear-gradient(268deg,#1EA97C, #8ED4BD 101.9%);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  @include respond-below(smallDevice) {
    position: fixed;
    left: 24px;
    right: 24px;
    bottom: 24px;
    width: unset;
  }
  &--error {
    background: linear-gradient(268deg, #D9342B 0.22%, #EC9995 101.9%);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  }
  &--warning {
    background: linear-gradient(268deg, #F59E0B 0.22%, #FACE85 101.9%);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  }
  &__icon {
    justify-self: center;
    align-self: center;
  }
  &__content {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    h3, p {
      color: var(--v-textPrimary-lighten5);
    }
  }
  &__title {
    font-size: 13px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.13px;
  }
  &__message {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.12px;
  }
  &__action {
    cursor: pointer;
  }
}
</style>
