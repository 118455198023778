<template>
  <svg
    width="33"
    height="39"
    viewBox="0 0 33 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2351 22.5533L5.62354 22.5916C4.54522 22.5971 3.66498 21.7091 3.66075 20.6282L3.64447 6.95111C3.65962 5.87445 4.52276 5.00616 5.60108 5.00068L27.2363 4.9472C28.3146 4.94172 29.1948 5.82971 29.1991 6.91067L29.2153 20.5877C29.2002 21.6644 28.3328 22.5521 27.2351 22.5533Z"
      stroke="#18222E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.67377 9.60768L28.7823 9.56334"
      stroke="#18222E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1087 18.731L7.09925 18.7503"
      stroke="#18222E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1048 15.854L7.09534 15.8734"
      stroke="#18222E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8376 12.9887L7.09618 12.9994"
      stroke="#18222E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.9999 34.745L29.9999 38.2111C29.9999 38.2804 29.9891 38.3444 29.9732 38.4037C29.8979 38.7442 29.5717 39 29.1862 39L3.80873 39C3.39642 39 3.05438 38.7088 3.01087 38.339C3 38.2943 3 38.255 3 38.2057L3 34.7396C3 34.3305 3.3638 34 3.80287 34C4.02198 34 4.22606 34.084 4.36989 34.2173C4.51458 34.3505 4.60574 34.5378 4.60574 34.7396L4.60574 37.5154L28.3993 37.5154L28.3993 34.7396C28.3993 34.3305 28.7631 34 29.2022 34C29.4213 34 29.6253 34.084 29.7692 34.2173C29.9088 34.3552 29.9999 34.5424 29.9999 34.745Z"
      fill="#18222E"
    />
    <path
      d="M19.7759 31.3622L16.5567 34.7474C16.5526 34.7567 16.5438 34.761 16.539 34.766C16.4209 34.8902 16.2681 34.9684 16.1152 34.9914C16.1022 34.9914 16.0893 34.9957 16.0756 34.9957C16.0497 35 16.0231 35 15.9971 35L15.9227 34.9957C15.9098 34.9957 15.8968 34.9914 15.8832 34.9914C15.7262 34.9684 15.5774 34.8902 15.4594 34.766C15.4553 34.7617 15.4464 34.7524 15.4416 34.7474L12.2225 31.3622C12.0737 31.2058 12 30.9991 12 30.7924C12 30.5858 12.0744 30.3791 12.2225 30.2226C12.5193 29.9105 13.0046 29.9105 13.3055 30.2226L15.2402 32.2571L15.2423 26.8038C15.2423 26.3631 15.5876 26 16.0066 26C16.2161 26 16.4086 26.0919 16.5485 26.2339C16.6884 26.3811 16.7709 26.5784 16.7709 26.8038L16.7709 32.2578L18.7056 30.2234C19.0025 29.9112 19.4877 29.9112 19.7887 30.2234C20.0726 30.5406 20.0727 31.0501 19.7759 31.3622Z"
      fill="#18222E"
    />
  </svg>
</template>

<script>
export default {
  name: 'Download'
};
</script>
