import {
  AxiosStorage,
  BuildStorage,
  CacheRequestConfig,
  NotEmptyStorageValue,
  StorageValue,
  canStale
} from 'axios-cache-interceptor';
import { RedisClientType } from 'redis';
import localforage from 'localforage';

/**
 * We don't want to keep indefinitely values in the storage if
 * their request don't finish somehow. Either set its value as
 * the TTL or 1 minute.
 *
 * Check: https://axios-cache-interceptor.js.org/guide/storages#node-redis-storage
 */
export function calculateExpiresAt(
  value: NotEmptyStorageValue,
  req: CacheRequestConfig<any, any>
): number {
  if (value.state === 'loading') {
    return (
      Date.now() +
      (req?.cache && typeof req.cache.ttl === 'number' ? req.cache.ttl : 60000) // 1 minute in seconds
    );
  }
  // When a stale state has a determined value to expire, we can use it.
  // Or if the cached value cannot enter in stale state.
  if (
    (value.state === 'stale' && value.ttl) ||
    (value.state === 'cached' && !canStale(value))
  ) {
    return value.createdAt + value.ttl;
  }

  // Otherwise, we can't determine when it should expire, so we keep it indefinitely.
  return undefined;
}

export function customRedisStorage(redisClient: RedisClientType): BuildStorage {
  return {
    async find(key: string) {
      const result = await redisClient.get(`axios-cache-${key}`);
      return result && (JSON.parse(result) as StorageValue);
    },
    async set(
      key: string,
      value: NotEmptyStorageValue,
      req: CacheRequestConfig<any, any>
    ): Promise<void> {
      await redisClient.set(`axios-cache-${key}`, JSON.stringify(value), {
        PXAT: calculateExpiresAt(value, req)
      });
    },
    async remove(key: string) {
      await redisClient.del(`axios-cache-${key}`);
    }
  };
}

export function localStorage(store: typeof localforage): BuildStorage {
  return {
    async find(key: string) {
      return await store.getItem(key);
    },
    async set(key: string, value: any) {
      await store.setItem(key, value);
    },
    async remove(key: string) {
      await store.removeItem(key);
    }
  };
}

/**
 * Removes each cached entry containing `searchKey` in the key name.
 */
async function searchKeys(
  searchKey: string,
  redisClient: RedisClientType,
  localStore: typeof localforage,
  enableRedis: boolean,
  storage: any
) {
  if (process.server && enableRedis) {
    // Redis client
    const keys = await redisClient.keys(`*${searchKey}*`);
    for (const key of keys) {
      await redisClient.del(key);
    }
  } else if (!process.server) {
    // localForage
    const keys = await localStore.keys();
    for (const key of keys) {
      if (key.includes(searchKey)) {
        await localStore.removeItem(key);
      }
    }
  } else {
    // Default memory storage
    const keys = Object.keys(storage.data);

    for (const key of keys) {
      if (key.includes(searchKey)) {
        await storage.remove(key);
      }
    }
  }
}

/**
 * Removes cache entries based on the PATCH/POST route.
 *
 * @param storage Axios cache storage.
 * @param config The axios request config.
 * @param client Redis client user for caching on the server side.
 * @param localStore Local storage in case the cache is on the client side, or redis is not enabled.
 */
export async function invalidate(
  storage: AxiosStorage,
  config: any,
  client: RedisClientType,
  localStore: typeof localforage,
  enableRedis: boolean
) {
  try {
    // Todo: Find out if this is possible.
    if (config.clearCacheEntry) {
      await storage.remove(config.cache.id);
    }
    if (config.method === 'post' || config.method === 'patch') {
      if (config.url.includes('/event')) {
        await searchKeys('/event', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/venue')) {
        await searchKeys('venue', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/ticket')) {
        await searchKeys('ticket', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/order')) {
        await searchKeys('order', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/user')) {
        await searchKeys('user', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/organizer')) {
        await searchKeys('organizer', client, localStore, enableRedis, storage);
      } else if (config.url.includes('/market-control')) {
        await searchKeys(
          'market-control',
          client,
          localStore,
          enableRedis,
          storage
        );
      } else if (config.url.includes('/fee')) {
        await searchKeys('fee', client, localStore, enableRedis, storage);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
