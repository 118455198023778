<!-- 
  For more info visit the following link:
  https://developers.google.com/identity/gsi/web/guides/display-button
 -->

<template>
  <client-only>
    <div id="google-button" />
  </client-only>
</template>

<script>
export default {
  name: 'BGoogleSignIn',
  data() {
    return {
      scriptUrl: 'https://accounts.google.com/gsi/client',
      scriptLoaded: false,
      listenersAttached: false
    };
  },
  mounted() {
    this.loadGoogleScript();
  },

  methods: {
    handleCredentialResponse(response) {
      this.$emit('login-success', response);
    },

    loadGoogleScript() {
      if (window.google && this.scriptLoaded) {
        // Script already loaded and initialized
        return;
      }
      const script = document.createElement('script');
      script.src = this.scriptUrl;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.renderButton();
        this.scriptLoaded = true;
      };
      document.head.appendChild(script);
    },

    renderButton() {
      try {
        window.google.accounts.id.initialize({
          client_id: this.$config.googleSSOClientId,
          callback: this.handleCredentialResponse,
          context: 'signin',
          ux_mode: 'popup',
          use_fedcm_for_prompt: true
        });

        window.google.accounts.id.renderButton(
          document.getElementById('google-button'),
          {
            theme: 'outline',
            text: 'signin_with',
            type: 'icon'
          }
        );

        window.google.accounts.id.prompt(); // This will display the One Tap prompt
      } catch (error) {
        this.$notifier.showMessage({
          content: `'Error rendering Google Sign-In button: ${error}'`,
          type: 'error'
        });
      }
    }
  }
};
</script>
<style>
#google-button {
  cursor: pointer;
}
</style>
