<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <circle cx="21" cy="21" r="21" fill="white"/>
    <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM22.4375 25.5781L22.6406 9.25H19.6094L19.8281 25.5781H22.4375ZM19.9375 29.3594C19.6562 29.6823 19.5156 30.0781 19.5156 30.5469C19.5156 30.9948 19.6562 31.3802 19.9375 31.7031C20.2292 32.026 20.6562 32.1875 21.2188 32.1875C21.7708 32.1875 22.1927 32.026 22.4844 31.7031C22.7865 31.3802 22.9375 30.9948 22.9375 30.5469C22.9375 30.0781 22.7865 29.6823 22.4844 29.3594C22.1927 29.0365 21.7708 28.875 21.2188 28.875C20.6562 28.875 20.2292 29.0365 19.9375 29.3594Z" 
      fill="#D9342B"
    />
  </svg>
</template>

<script>
  export default {
    name: 'IconError'
  }
</script>