<template>
  <div
    ref="select"
    v-click-outside="() => onClose()"
    class="select__wrapper"
    :class="{
      'select__wrapper--block': block,
      'select__wrapper--disabled': disabled,
      'select__wrapper--error': error,
      'select__wrapper--small': small,
      'select__wrapper--full-width': fullWidth,
      'select__wrapper--rounded': rounded,
      'select__wrapper--bottom-border': bottomBorder
    }"
    @click="onWindowChange"
  >
    <div
      class="select"
      :class="{
        'select--small': small
      }"
      @click="dropDown()"
    >
      <p
        v-if="selected === undefined"
        class="select__placeholder"
        :class="{
          'select__placeholder--small': small
        }"
      >
        {{ placeholder }}
      </p>
      <p
        v-else
        class="select__value"
        :class="{
          'select__value--small': small
        }"
      >
        {{ selected.content }}
      </p>
      <div class="select__dropdown"></div>
    </div>
    <BMenu
      v-if="options.length > 0"
      v-show="dropdown"
      ref="menu"
      v-closable="{
        exclude: ['select'],
        handler: 'onClose'
      }"
      :items="items"
      :width="width"
      :match-content-width="matchContentWidth"
      @selectedItem="selectOption"
    />
    <p v-if="infoText" class="select__info-text">
      {{ infoText }}
    </p>
  </div>
</template>
<script>
import BMenu from '@/components/general/BMenu.vue';

export default {
  name: 'BSelect',
  components: { BMenu },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    block: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Boolean],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    immediateEmit: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    matchContentWidth: {
      type: Boolean,
      default: false
    },
    dynamicOptions: {
      type: Boolean,
      default: false
    },
    bottomBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dropdown: false,
      items: [],
      selected: {
        id: null,
        content: ''
      },
      width: 0
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!this.immediateEmit) {
          return;
        }
        this.$emit('input', this.selected?.id);
        this.selected = this.items.find((item) => item.id === val);
      },
      immediate: true
    },
    options: {
      handler(val) {
        if (!this.dynamicOptions) {
          return;
        }
        this.items = val.map((option) => {
          return {
            id: option.id,
            content: option.displayName
          };
        });
        this.items = this.items.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );
        this.selected = this.items.find((item) => item.id === this.value);
      },
      immediate: true
    }
  },
  mounted() {
    if (this.options.length > 0) {
      this.items = this.options.map((option) => {
        return {
          id: option.id,
          content: option.displayName
        };
      });
      this.items = this.items.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      this.selected = this.items.find((item) => item.id === this.value);
      this.$emit('input', this.selected?.id);
      this.$emit('update:modelValue', this.selected);
      // add listener for scroll to position dropdown menu
      if (document.querySelector('.content') !== null) {
        document
          .querySelector('.content')
          .addEventListener('scroll', this.onWindowChange);
      }
      this.onWindowChange();
    }
    // add listener for window resize and scroll to position dropdown menu
    window.addEventListener('resize', this.onWindowChange);
    window.addEventListener('scroll', this.onWindowChange);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowChange);
    if (document.querySelector('.content') !== null) {
      document
        .querySelector('.content')
        .removeEventListener('scroll', this.onWindowChange);
    }
    window.removeEventListener('scroll', this.onWindowChange);
  },
  methods: {
    onWindowChange() {
      // get position of select element
      const { top, left, height } = this.$refs.select.getBoundingClientRect();
      // get height of the menu
      const menuHeight = this.$refs.menu.$el.offsetHeight;
      // calculate available space below the dropdown
      const spaceBelow = window.innerHeight - top - height;
      // set position of dropdown menu
      if (spaceBelow >= menuHeight) {
        // position menu below dropdown
        this.$refs.menu.$el.style.transform = `translate(${left}px, ${
          top + height + 8
        }px)`;
      } else {
        // position menu above dropdown
        this.$refs.menu.$el.style.transform = `translate(${left}px, ${
          top - menuHeight
        }px)`;
      }
    },
    dropDown() {
      this.dropdown = !this.dropdown;
      this.width = this.$refs.select.offsetWidth;
    },
    selectOption(option) {
      this.$emit('input', option);
      this.$emit('update:modelValue', option);
      this.selected = this.items.find((item) => item.id === option);
      this.dropdown = false;
    },
    onClose() {
      this.dropdown = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  margin-bottom: 4px;
  display: grid;
  grid-template-columns: 1fr 48px;
  &__wrapper {
    position: relative;
    background: var(--v-backgroundPrimary-lighten5);
    height: 48px;
    width: 100%;
    max-width: 648px;
    margin-bottom: 16px;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    &--full-width {
      .select__placeholder,
      .select__value {
        width: max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &--bottom-border {
      border-bottom: 1px solid var(--v-borderPrimary-lighten4);
    }
    .menu {
      position: fixed;
      top: 0;
      left: 0;
    }
    &--block {
      max-width: initial;
    }
    &--disabled {
      pointer-events: none;
      border-bottom: 1px solid var(--v-borderPrimary-lighten4);
      color: var(--v-textPrimary-lighten4);
      background-color: var(--v-backgroundGray-lighten1);
      p {
        color: var(--v-textPrimary-lighten4);
      }
    }
    &--error {
      border-bottom: 1px solid var(--v-textHighlightError-base);
      color: var(--v-textHighlightError-base);
      background-color: var(--v-backgroundHighlightTransparentError-base);
      p {
        color: var(--v-textHighlightError-base);
      }
    }
    &--rounded {
      border-radius: 4px;
    }
    &--small {
      height: 38px;
      margin-bottom: 0;
    }
  }
  &__placeholder {
    color: var(--v-textPrimary-lighten3);
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    padding-left: 12px;
    margin: 0;
    user-select: none;
    &--small {
      line-height: 37px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__value {
    color: var(--v-textPrimary-base);
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    padding-left: 12px;
    margin: 0;
    user-select: none;
    &--small {
      line-height: 37px;
    }
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__dropdown {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 5px solid;
    border-right: 5px solid;
    transform: rotate(135deg);
    color: var(--v-backgroundPrimary-base);
    align-self: center;
    justify-self: center;
  }
  &__info-text {
    font: inherit;
    font-size: 12px;
    margin: 8px 0;
    letter-spacing: 0.04em;
    color: var(--v-textPrimary-lighten3);
  }
  &--small {
    height: 37px;
  }
}
</style>
