import { USER_ROLES } from '../utils/utils';

export default function({ store, redirect }) {
  if (
    process.client &&
    store.getters['user/getOrgsWithPerms'](
      USER_ROLES.EVENT_MANAGEMENT.permissions
    ).length === 0
  ) {
    return redirect('/');
  }
}
