var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout v-application",attrs:{"id":"app","data-app":"true"}},[_c('client-only',[(_vm.getDashboardMenu.state === 'dashboard')?_c('aside',{staticClass:"layout__aside"},[_c('div',{staticClass:"aside__logo",on:{"click":function($event){return _vm.gotoHome()}}},[_c('img',{attrs:{"src":require("assets/img/logo_dashboard.svg"),"alt":"Logo"}})]),_vm._v(" "),_c('div',{staticClass:"aside__selector"},[(_vm.organizers.length)?_c('BSelect',{staticClass:"aside__selector__margin",attrs:{"options":_vm.organizers,"value":_vm.organizer.id,"small":"","rounded":"","immediate-emit":false,"disabled":_vm.isRuleEditPage,"bottom-border":false,"data-cy":"selectOrganizer"},on:{"input":_vm.organizerSelected}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"aside__content"},[_c('div',{staticClass:"aside__menu aside__menu--center"},_vm._l((_vm.userMenuItems),function(item){return _c('div',{key:item.name,staticClass:"aside__item",class:{
              hidden:
                (item.type === 'admin' && !_vm.canAccessAdmin) ||
                (item.type === 'dashboard' && !_vm.canAccessDashboard) ||
                (item.type === 'accounting' && !_vm.canAccessAccounting)
            }},[_c('h3',{staticClass:"aside__title"},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]),_vm._v(" "),_c('ul',_vm._l((item.subItems),function(subItem){return _c('li',{key:subItem.name,staticClass:"aside__main",class:{
                  active:
                    _vm.getDashboardMenuActive === subItem.name &&
                    !subItem.expanded,
                  disabled: subItem.disabled,
                  dropdown: subItem.subItems,
                  visible: subItem.subItems && subItem.expanded
                }},[_c('client-only',[_c(subItem.icon,{tag:"component"})],1),_vm._v(" "),_c('p',{class:{ dropdown__expand: subItem.subItems },attrs:{"data-cy":subItem.name},on:{"click":function($event){return subItem.onClick(subItem)}}},[_vm._v("\n                  "+_vm._s(subItem.label)+"\n                  "),(subItem.subItems)?_c('IconArrowDown',{class:{ expanded: subItem.expanded },attrs:{"size":"20","light":""}}):_vm._e()],1),_vm._v(" "),(subItem.subItems)?_c('div',{staticClass:"aside__subItem aside__subItem--spaced",class:{
                    visible: _vm.dropdownItems.find(
                      (dropdownItem) => dropdownItem.name === subItem.name
                    )?.expanded
                  }},[_c('ul',_vm._l((subItem.subItems),function(dropdownItem){return _c('li',{key:dropdownItem.name,class:{
                        active: _vm.getDashboardMenuActive === dropdownItem.name
                      }},[_c('p',{staticClass:"aside__subItem--inside",on:{"click":dropdownItem.onClick}},[_vm._v("\n                        "+_vm._s(dropdownItem.label)+"\n                      ")])])}),0)]):_vm._e()],1)}),0)])}),0)])]):(_vm.getDashboardMenu.state === 'event')?_c('aside',{staticClass:"layout__aside"},[_c('div',{class:`aside__${_vm.getDashboardMenu.state}`},[_c('a',{on:{"click":function($event){return _vm.gotoEvents()}}},[_vm._v("< "+_vm._s(_vm.$t('sideMenu.dashboard')))])]),_vm._v(" "),_c('div',{staticClass:"aside__eventInfo"},[_c('h3',{staticClass:"aside__eventInfo__title"},[_vm._v("\n          "+_vm._s(_vm.getDashboardMenu.event.name)+"\n        ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('sideMenu.date'))+": "+_vm._s(_vm.getDashboardMenu.event.date))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('sideMenu.time'))+": "+_vm._s(_vm.getDashboardMenu.event.time))])]),_vm._v(" "),_c('div',{staticClass:"aside__content"},[_c('div',{staticClass:"aside__menu aside__menu--event-menu"},_vm._l((_vm.eventMenuItems),function(item){return _c('div',{key:item.name,staticClass:"aside__item",class:{ 'aside__item--hidden': item.hidden }},[_c('h3',{staticClass:"aside__title aside__title--submenu"},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]),_vm._v(" "),_c('ul',_vm._l((item.subItems),function(subItem){return _c('li',{key:subItem.name,class:{
                  active: _vm.getDashboardMenuActive === subItem.name,
                  dropdown: subItem.subItems,
                  visible: subItem.subItems && subItem.expanded,
                  disabled: subItem.disabled
                }},[_c('p',{staticClass:"aside__subItem",class:{
                    'aside__subItem--hidden': subItem.hidden,
                    dropdown__expand: subItem.subItems
                  },attrs:{"data-cy":subItem.name},on:{"click":function($event){return subItem.onClick(subItem)}}},[_vm._v("\n                  "+_vm._s(subItem.label)+"\n                  "),(subItem.subItems)?_c('IconArrowDown',{class:{ expanded: subItem.expanded },attrs:{"size":"20","light":""}}):_vm._e()],1),_vm._v(" "),(subItem.subItems)?_c('div',{staticClass:"aside__subItem--spaced",class:{
                    visible: _vm.dropdownItems.find(
                      (dropdownItem) => dropdownItem.name === subItem.name
                    ).expanded
                  }},[_c('ul',_vm._l((subItem.subItems),function(dropdownItem){return _c('li',{key:dropdownItem.name,class:{
                        active: _vm.getDashboardMenuActive === dropdownItem.name
                      }},[_c('p',{staticClass:"aside__subItem--inside",on:{"click":dropdownItem.onClick}},[_vm._v("\n                        "+_vm._s(dropdownItem.label)+"\n                      ")])])}),0)]):_vm._e()])}),0)])}),0)])]):(_vm.getDashboardMenu.state === 'profile')?_c('aside',{staticClass:"layout__aside"},[_c('div',{staticClass:"aside__logo",on:{"click":function($event){return _vm.gotoHome()}}},[_c('img',{attrs:{"src":require("assets/img/logo_dashboard.svg"),"alt":"Logo"}})]),_vm._v(" "),_c('div',{staticClass:"aside__content"},[_c('div',{staticClass:"aside__menu aside__menu--profile"},_vm._l((_vm.profileMenuItems),function(item){return _c('div',{key:item.name,staticClass:"aside__item"},[_c('h3',{staticClass:"aside__title aside__title--profile-submenu"},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]),_vm._v(" "),_c('ul',_vm._l((item.subItems),function(subItem){return _c('li',{key:subItem.name,class:{
                  active: _vm.getDashboardMenuActive === subItem.name
                }},[_c('p',{staticClass:"aside__subItem",class:{ 'aside__subItem--hidden': subItem.hidden },on:{"click":function($event){return subItem.onClick(subItem)}}},[_vm._v("\n                  "+_vm._s(subItem.label)+"\n                ")])])}),0)])}),0)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"layout__content"},[_c('client-only',[_c('BHeader',{attrs:{"color":_vm.headerColor,"is-dashboard":true}})],1),_vm._v(" "),_c('div',{staticClass:"content"},[_c('nuxt')],1)],1)]),_vm._v(" "),_c('BAlert'),_vm._v(" "),_c('BLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.getLoading),expression:"getLoading"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }