<template>
  <div
    class="menu"
    :style="{
      width: matchContentWidth ? 'auto' : width + 'px'
    }"
  >
    <ul class="menu__content">
      <li v-for="item in items" :key="item.id" @click="itemSelected(item.id)">
        <v-icon v-if="item.icon" class="menu__content__icon" :size="iconSize"
          >{{ item.icon }}
        </v-icon>
        <span v-if="item.content">{{ item.content }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BMenu',
  props: {
    matchContentWidth: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: Number,
      default: 16
    },
    items: {
      type: Array,
      default: null
    },
    width: {
      type: Number,
      default: 0
    }
  },
  methods: {
    itemSelected(id) {
      this.$emit('selectedItem', id);
    }
  }
};
</script>
<style scoped lang="scss">
.menu {
  overflow-y: auto;
  max-height: 40vh;
  width: auto;
  position: absolute;
  z-index: 24;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1), 0px 7px 15px rgba(37, 42, 47, 0.1), 0px 4px 16px rgba(37, 42, 47, 0.05);
  cursor: pointer;
  
  &__content {
    border-radius: 4px;
    background-color: var(--v-backgroundGray-lighten3);
    min-width: auto;
    z-index: 24;
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__icon {
      margin-right: 5px;
    }

    li {
      border-radius: 4px;
      color: var(--v-textPrimary-base);
      padding: 12px 16px 12px 12px;
      list-style-type: none;
      box-sizing: border-box;
      &:hover {
        background-color: var(--v-backgroundGray-base);
      }
      &:not(:last-child) {
        border-bottom: solid 1px var(--v-borderPrimary-lighten4);
      }
      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
