<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.44 11.9333L14.1333 20.6267C15.16 21.6533 16.84 21.6533 17.8667 20.6267L26.56 11.9333"
      :stroke="color"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'IconArrowDown',
  props: {
    light: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '32'
    }
  },
  computed: {
    color() {
      return this.light
        ? this.$whitelabel.lightTheme.backgroundPrimary.lighten5
        : this.$whitelabel.lightTheme.backgroundPrimary.base;
    }
  }
});
</script>
