// TODO: Add proper types
export const state = () => ({
  data: null,
  standardQuestions: null
});

export const mutations = {
  setData(state, payload) {
    state.data = payload;
  },
  setStandardQuestions(state, payload) {
    state.standardQuestions = payload;
  },
  setExpanded(state, payload) {
    state.data[payload].expanded = !state.data[payload].expanded;
  }
};

export const actions: any = {
  setData({ commit }, payload) {
    commit('setData', payload);
  },
  setStandardQuestions({ commit }, payload) {
    commit('setStandardQuestions', payload);
  }
};

export const getters = {
  getQuestionnaire(state) {
    return state.data;
  },
  getQuestions(state) {
    return state.questions;
  },
  getStandardQuestions(state) {
    return state.standardQuestions;
  }
};
