const PROTOCOL = 'https';

export default (baseUrl: string) => ({
  baseUrl() {
    return baseUrl;
  },
  eventServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/event/v1`;
  },

  accountServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/account/v1`;
  },

  paymentServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/payment/v1`;
  },

  websocketServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/websocket/v1`;
  },
});
