
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'IconDocument',
  props: {
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    color() {
      return this.disabled
        ? this.$whitelabel.lightTheme.textPrimary.lighten4
        : this.$whitelabel.lightTheme.textPrimary.base;
    }
  }
});
