<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <circle cx="21" cy="21" r="21" fill="white"/>
    <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM30.6206 12.7421C30.8923 12.2613 30.7229 11.6512 30.2421 11.3794C29.7613 11.1077 29.1512 11.2771 28.8794 11.7579L18.6263 29.8981L11.1853 22.8967C10.783 22.5182 10.1502 22.5375 9.7717 22.9397C9.39324 23.342 9.41251 23.9748 9.81474 24.3533L18.1843 32.2283C18.408 32.4388 18.716 32.5348 19.0197 32.4887C19.3234 32.4426 19.589 32.2594 19.7401 31.9921L30.6206 12.7421Z" 
      fill="#1EA97C"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSuccess'
};
</script>