var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.onClose()),expression:"() => onClose()"}],ref:"select",staticClass:"select__wrapper",class:{
    'select__wrapper--block': _vm.block,
    'select__wrapper--disabled': _vm.disabled,
    'select__wrapper--error': _vm.error,
    'select__wrapper--small': _vm.small,
    'select__wrapper--full-width': _vm.fullWidth,
    'select__wrapper--rounded': _vm.rounded,
    'select__wrapper--bottom-border': _vm.bottomBorder
  },on:{"click":_vm.onWindowChange}},[_c('div',{staticClass:"select",class:{
      'select--small': _vm.small
    },on:{"click":function($event){return _vm.dropDown()}}},[(_vm.selected === undefined)?_c('p',{staticClass:"select__placeholder",class:{
        'select__placeholder--small': _vm.small
      }},[_vm._v("\n      "+_vm._s(_vm.placeholder)+"\n    ")]):_c('p',{staticClass:"select__value",class:{
        'select__value--small': _vm.small
      }},[_vm._v("\n      "+_vm._s(_vm.selected.content)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"select__dropdown"})]),_vm._v(" "),(_vm.options.length > 0)?_c('BMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdown),expression:"dropdown"},{name:"closable",rawName:"v-closable",value:({
      exclude: ['select'],
      handler: 'onClose'
    }),expression:"{\n      exclude: ['select'],\n      handler: 'onClose'\n    }"}],ref:"menu",attrs:{"items":_vm.items,"width":_vm.width,"match-content-width":_vm.matchContentWidth},on:{"selectedItem":_vm.selectOption}}):_vm._e(),_vm._v(" "),(_vm.infoText)?_c('p',{staticClass:"select__info-text"},[_vm._v("\n    "+_vm._s(_vm.infoText)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }