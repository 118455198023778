import { User, Order, Event } from '@bam/sdk';

/**
 * See property descriptions at https://developers.facebook.com/docs/meta-pixel/reference#object-properties
 */
export interface MetaCustomData {
  content_category?: string;
  content_ids?: (number | string)[];
  content_name?: string;
  content_type?: string;
  contents?: { id: number | string; quantity: number }[];
  currency?: string;
  value?: number;
  num_items?: number;
  search_string?: string;
  status?: boolean;
  [key: string]: unknown;
}

/**
 * Creates the Meta pixel advanced matching data from the users' data
 * See https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
 * for more information on the user data that can be sent to the meta pixel
 */
export function getMetaAdvancedMatchingData(user: User) {
  if (!user) {
    return {};
  }

  return {
    external_id: user.id,
    em: user.email?.toLowerCase(),
    fn: user.firstName?.toLowerCase(),
    ln: user.lastName?.toLowerCase()
  };
}

/**
 * Creates the Meta pixel `Purchase` event data from the order data
 */
export function toPurchaseEvent(order: Order): MetaCustomData {
  return {
    content_category: 'Order',
    content_ids: [order.id],
    content_type: 'product_group',
    currency: order.currency,
    value: order.total
  };
}

/**
 * Creates the Meta pixel `AddPaymentInfo` event data from the order data
 */
export function toAddPaymentInfoEvent(order: Order): MetaCustomData {
  return {
    content_category: 'Order',
    content_ids: [order.id],
    content_type: 'product_group',
    currency: order.currency,
    value: order.total
  };
}

/**
 * Creates the Meta pixel `InitiateCheckout` event data from the order data
 */
export function toInitiateCheckoutEvent(order: Order): MetaCustomData {
  const numItems = order.orderItem?.reduce(
    (acc, orderItem) => acc + orderItem?.ticket?.length,
    0
  );
  const contents = order.orderItem?.map((item) => ({
    id: item.ticket?.[0]?.ticketConfig?.id,
    quantity: item.ticket?.length
  }));

  return {
    content_category: 'TicketConfig',
    content_type: 'product',
    contents,
    num_items: numItems,
    currency: order.currency,
    value: order.total
  };
}

/**
 * Creates the Meta pixel `OccurrenceClick` custom event data from the event
 */
export function toOccurrenceClickEvent(event: Event): MetaCustomData {
  return {
    content_category: 'Occurrence',
    content_type: 'product_group',
    content_ids: [event.id],
    content_name: event.name,
    // custom props below, see https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/#tracking-custom-events
    start_at: event.startAt,
    end_at: event.endAt
  };
}

/**
 * Creates the Meta pixel `CompleteRegistration` event data from the user data
 */
export function toCompleteRegistrationEvent(user: User): MetaCustomData {
  return {
    content_category: 'User',
    content_ids: [user.id]
  };
}

/**
 * Creates the Meta pixel `Search` event data
 */
export function toSearchEventData(searchTerm: string): MetaCustomData {
  return {
    search_string: searchTerm
  };
}
