
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    classIcon(): string {
      return `icon--${this.icon}`;
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    }
  }
});
