<template>
  <div
    class="input"
    :class="{
      'input--filled': isFilled,
      'input--error': errorOccurred || error,
      'input--disabled': disabled,
      'input--focused': focus,
      'input--text-right': rightOrientation,
      'input--compact': compact,
      'input--date': type === 'date',
      'input--time': type === 'time'
    }"
  >
    <div
      class="input__wrapper"
      :class="{
        'input__wrapper--icon-left': leftIcon,
        'input__wrapper--icon-right': rightIcon,
        'input__wrapper--text-right': rightOrientation
      }"
      @click="onClick()"
    >
      <label v-if="label" class="input__label">{{
        label + (required ? ' *' : '')
      }}</label>
      <input
        ref="input"
        v-model="model"
        class="input__field"
        :class="{
          'input__field--filled': isFilled,
          'input__field--text-right': rightOrientation
        }"
        tabindex="0"
        :type="inputType"
        :disabled="disabled"
        :maxlength="maximumInputLength"
        :max="maxValue"
        :min="minValue"
        @blur="onBlur($event)"
        @focus="onFocus($event)"
        @keyup.enter="onEnterKey"
        @keyup="onKeyUp"
        @paste="onPaste($event)"
        @focusout="onFocusOut($event)"
        @input="onInput($event)"
      />
      <template v-if="rightIcon && typePassword">
        <transition name="fade">
          <IconEye
            v-if="inputType === 'password'"
            class="input__icon input__icon--right input__icon--clickable"
            @click.native="toggleType(inputType)"
          >
          </IconEye>
          <IconEyeSlash
            v-else
            class="input__icon input__icon--right input__icon--clickable"
            @click.native="toggleType(inputType)"
          >
          </IconEyeSlash>
        </transition>
      </template>
      <b-icon
        v-else-if="rightIcon"
        class="input__icon input__icon--right"
        :icon="rightIcon"
      >
      </b-icon>
      <b-icon
        v-if="leftIcon"
        class="input__icon input__icon--left"
        :icon="leftIcon"
      >
      </b-icon>
      <div v-if="type === 'date'" class="input__type">
        <IconDate />
      </div>
      <div v-if="type === 'time'" class="input__type">
        <IconTime />
      </div>
    </div>
    <div
      class="input__info-text"
      :class="{
        'input__info-text--clickable': infoTextClickable,
        'input__info-text--right': rightInfo
      }"
    >
      <span @click="infoMessageClicked">{{
        infoMessage ? infoMessage : ''
      }}</span>
      <span v-if="counterValue">{{ model.length }} / {{ counterValue }}</span>
    </div>
  </div>
</template>

<script>
import BIcon from './BIcon.vue';
import IconDate from './icons/IconDate.vue';
import IconTime from './icons/IconTime.vue';
import IconEye from './icons/IconEye.vue';
import IconEyeSlash from './icons/IconEyeSlash.vue';

import {
  emailIsValid,
  usernameIsValid,
  usernameOrEmailIsValid,
  passwordIsValid,
  organizerNameIsValid,
  organizerDisplayNameIsValid,
  linkIsValid,
  numberIsValid,
  maxValue,
  minValue,
  maxTicketQuantity,
  isInteger,
  phoneNumberIsValid
} from '~/utils/validation';
export default {
  name: 'BInputField',
  components: { BIcon, IconDate, IconTime, IconEye, IconEyeSlash },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    infoText: {
      type: String,
      default: ''
    },
    rightOrientation: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    compact: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    noValidate: {
      type: Boolean,
      default: false
    },
    counterValue: {
      type: Number,
      default: null
    },
    maxQuantity: {
      type: Boolean,
      default: false
    },
    integer: {
      type: Boolean,
      default: false
    },
    confirmPassword: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: Boolean,
      default: false
    },
    infoTextClickable: {
      type: Boolean,
      default: false
    },
    rightInfo: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'hasError', 'infoTextClicked'],
  data() {
    return {
      inputType: this.type,
      focus: false,
      errorOccurred: false,
      infoMessage: this.infoText,
      fieldTypes: [
        'userEmail',
        'username',
        'usernameOrEmail',
        'organizerName',
        'displayName',
        'link'
      ],
      maximumInputLength: this.maxLength
    };
  },
  computed: {
    isFilled() {
      return (
        this.model !== null &&
        (this.model?.length > 0 ||
          (this.model !== '' && !isNaN(Number(this.model))))
      );
    },
    typePassword() {
      return this.rightIcon === 'show';
    },
    maxValue() {
      if (this.max && this.type === 'number') {
        return this.max;
      }
      return null;
    },
    minValue() {
      if (this.min && this.type === 'number') {
        return this.min;
      }
      return null;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    infoText(newValue) {
      this.infoMessage = newValue;
    },
    value(newValue) {
      this.model = newValue;
      setTimeout(() => {
        this.validateInput(newValue);
      }, 100);
    }
  },
  mounted() {
    if (this.fieldTypes.includes(this.type)) {
      this.inputType = 'text';
    }
    if (this.counterValue) {
      this.maximumInputLength = this.counterValue;
    }
    if (this.type === 'confirm_password') {
      this.inputType = 'password';
    }
  },
  methods: {
    onPaste(e) {
      this.$emit('input', e.target.value);
      setTimeout(() => {
        this.validateInput(e.target.value);
      }, 100);
    },
    onKeyUp(e) {
      this.$emit('input', e.target.value);
      setTimeout(() => {
        this.validateInput(e.target.value);
      }, 100);
    },
    onClick() {
      this.focus = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 100);
    },
    onInput(e) {
      if (this.inputType !== 'password') {
        return;
      }
      this.$emit('input', e.target.value);
      setTimeout(() => {
        this.validateInput(e.target.value);
      }, 100);
    },
    onFocus(ev) {
      this.focus = true;
    },
    onEnterKey() {
      this.$emit('enterKeyPressed');
    },
    onFocusOut(e) {
      this.focus = false;
      if (e.relatedTarget) {
        this.validateInput(e.target.value);
      }
    },
    onBlur(e) {
      this.focus = false;
      if (e.relatedTarget) {
        this.validateInput(e.target.value);
      }
    },
    toggleType(type) {
      this.inputType = type === 'password' ? 'text' : 'password';
    },
    infoMessageClicked() {
      this.$emit('infoTextClicked');
    },
    validateInput(eVal) {
      if (this.noValidate) {
        return;
      }
      if (!this.value && !this.required) {
        this.errorOccurred = false;
        this.infoMessage = '';
        return;
      }
      if (this.required && this.value === '') {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.required');
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      if (this.maxValue && !maxValue(this.value, this.maxValue)) {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.max_value', {
          max: this.maxValue
        });
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      if (this.minValue && !minValue(this.value, this.minValue)) {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.min_value', {
          min: this.minValue
        });
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      if (this.maxQuantity && !maxTicketQuantity(this.value)) {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.max_quantity');
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      if (this.integer && !isInteger(this.value)) {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.integer');
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      if (this.phoneNumber && !phoneNumberIsValid(this.value)) {
        this.errorOccurred = true;
        this.infoMessage = this.$t('validations.phoneNumber');
        this.$emit('hasError', this.errorOccurred);
        return;
      }
      switch (this.type) {
        case 'userEmail':
          this.errorOccurred = !emailIsValid(eVal);
          this.infoMessage = !emailIsValid(eVal)
            ? this.$t('validations.email')
            : '';
          break;
        case 'username':
          this.errorOccurred = !usernameIsValid(eVal);
          this.infoMessage = !usernameIsValid(eVal)
            ? this.$t('validations.username')
            : '';
          break;
        case 'usernameOrEmail':
          this.errorOccurred = !usernameOrEmailIsValid(eVal);
          this.infoMessage = !usernameOrEmailIsValid(eVal)
            ? this.$t('validations.emailOrUsername')
            : '';
          break;
        case 'password':
          this.errorOccurred = !passwordIsValid(eVal);
          this.infoMessage = !passwordIsValid(eVal)
            ? this.$t('validations.password')
            : '';
          break;
        case 'confirm_password':
          this.errorOccurred = this.confirmPassword !== eVal;
          this.infoMessage =
            this.confirmPassword !== eVal
              ? this.$t('validations.confirm_password')
              : '';
          break;
        case 'organizerName':
          this.errorOccurred = !organizerNameIsValid(eVal);
          this.infoMessage = !organizerNameIsValid(eVal)
            ? this.$t('validations.organizer_name')
            : '';
          break;
        case 'displayName':
          this.errorOccurred = !organizerDisplayNameIsValid(eVal);
          this.infoMessage = !organizerDisplayNameIsValid(eVal)
            ? this.$t('validations.organizer_display_name')
            : '';
          break;
        case 'link':
          this.errorOccurred = !linkIsValid(eVal);
          this.infoMessage = !linkIsValid(eVal)
            ? this.$t('validations.valid_link')
            : '';
          break;
        case 'number':
          this.errorOccurred = !numberIsValid(eVal);
          this.infoMessage = !numberIsValid(eVal)
            ? this.$t('validations.valid_number')
            : '';
          break;
        default:
          this.errorOccurred = false;
          this.infoMessage = '';
          break;
      }
      this.$emit('hasError', this.errorOccurred);
    }
  }
};
</script>
