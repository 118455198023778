<template>
  <svg
    width="70"
    height="48"
    viewBox="0 0 70 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.16274 45.7044V1.47524H67.8373V45.7044H2.16274ZM0.6875 1.19863C0.6875 0.536645 1.22415 0 1.88613 0H68.1139C68.7759 0 69.3125 0.536646 69.3125 1.19863V45.9811C69.3125 46.643 68.7759 47.1797 68.1139 47.1797H1.88613C1.22414 47.1797 0.6875 46.643 0.6875 45.9811V1.19863ZM3.73314 4.51639L34.2104 25.014C34.8325 25.4324 35.6469 25.4296 36.266 25.0068L66.2788 4.51364L65.4916 3.36082L35.4789 23.854C35.3315 23.9546 35.1376 23.9553 34.9894 23.8557L4.51218 3.35807L3.73314 4.51639Z"
      :fill="getColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconEmail',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    getColor() {
      if (this.color) {
        return this.color;
      }
      if (this.disabled) {
        return this.$whitelabel.lightTheme.textPrimary.lighten4;
      }
      return this.$whitelabel.lightTheme.buttonPrimary.lighten4;
    }
  }
};
</script>
