// TODO: Add proper types
export const state = () => ({
  isLoading: true,
  authDialog: false,
  dashboardAsideMenu: false,
  dashboardMenu: {
    state: 'dashboard',
    active: 'my-events',
    event: {
      name: null,
      date: null,
      time: null
    }
  },
  sendTickets: false,
  enableGuestLogin: false,
  organizer: null,
  organizers: [],
  organizerInToken: null // Which organizer is currently present in the users' tokens' permissions
});

export const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setAuthDialog(state, payload) {
    state.authDialog = payload;
  },
  setDashboardAsideMenu(state, payload) {
    state.dashboardAsideMenu = payload;
  },
  setDashboardMenu(state, payload) {
    state.dashboardMenu = payload;
  },
  setDashboardMenuActive(state, payload) {
    state.dashboardMenu.active = payload;
  },
  setOrganizer(state, payload) {
    state.organizer = payload;
  },
  setOrganizers(state, payload) {
    state.organizers = payload;
  },
  setEventSendTickets(state, payload) {
    state.sendTickets = payload;
  },
  setEnableGuestLogin(state, payload) {
    state.enableGuestLogin = payload;
  },
  setOrganizerInToken: (state, payload) => {
    state.organizerInToken = payload;
  },
  clearAuthData(state) {
    state.isLoading = false;
    state.authDialog = false;
    state.dashboardAsideMenu = false;
    state.dashboardMenu = {
      state: 'dashboard',
      active: 'my-events',
      event: {
        name: null,
        date: null,
        time: null
      }
    };
    state.sendTickets = false;
    state.enableGuestLogin = false;
    state.organizer = null;
    state.organizers = [];
  }
};

export const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getAuthDialog: (state) => {
    return state.authDialog;
  },
  getDashboardAsideMenu: (state) => {
    return state.dashboardAsideMenu;
  },
  getDashboardMenu: (state) => {
    return state.dashboardMenu;
  },
  getDashboardMenuActive: (state) => {
    return state.dashboardMenu.active;
  },
  getOrganizer: (state) => {
    return state.organizer;
  },
  getOrganizers: (state) => {
    return state.organizers;
  },
  getEventSendTickets: (state) => {
    return state.sendTickets;
  },
  getEnableGuestLogin: (state) => {
    return state.enableGuestLogin;
  },
  userCanAccessDashboard: (state) => {
    return state.organizers.length > 0;
  },
  getOrganizerInToken: (state) => {
    return state.organizerInToken;
  }
};

export const actions = {
  setOrganizerInToken: ({ commit }, payload) => {
    commit('setOrganizerInToken', payload);
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setOrganizer({ commit }, payload) {
    commit('setOrganizer', payload);
  },
  setOrganizers({ commit }, payload) {
    commit('setOrganizers', payload);
  },
};
