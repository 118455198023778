<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.88974 5.13184C5.69669 5.13184 5.50092 5.20676 5.3538 5.35386C5.06013 5.64754 5.06013 6.13207 5.3538 6.42573L10.6781 11.7501L5.3538 17.0744C5.06013 17.3681 5.06013 17.8526 5.3538 18.1463C5.49599 18.2885 5.69451 18.3683 5.88974 18.3683C6.08497 18.3683 6.28349 18.2885 6.42567 18.1463L11.75 12.8219L17.0744 18.1463C17.2165 18.2885 17.4151 18.3683 17.6103 18.3683C17.8022 18.3683 17.9947 18.2978 18.1462 18.1463C18.4399 17.8526 18.4399 17.3681 18.1462 17.0744L12.8219 11.7501L18.1462 6.42573C18.4399 6.13205 18.4399 5.64753 18.1462 5.35386C17.8525 5.06073 17.368 5.06018 17.0744 5.35386L11.75 10.6782L6.42567 5.35386C6.27911 5.2073 6.08333 5.13184 5.88974 5.13184Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCancel'
};
</script>
