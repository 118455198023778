var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"button",class:{
    'button--fill': _vm.fill,
    'button--disabled': _vm.disabled,
    'button--destructive': _vm.destructive,
    'button--rounded': _vm.rounded,
    'button--block': _vm.block,
    'button--transparent': _vm.transparent
  },attrs:{"disabled":_vm.disabled}},[(_vm.leftIcon)?_c('b-icon',{staticClass:"button__icon",attrs:{"icon":_vm.leftIcon}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.rightIcon)?_c('b-icon',{staticClass:"button__icon",attrs:{"icon":_vm.rightIcon}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }