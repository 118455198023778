import momentZone from 'moment-timezone';

import { getTimezoneFromName } from './utils';

/**
 * Utility function to apply the event timezone on the field.
 * @param time The specified time the timezone should be applied.
 * @param timezoneName Name of the timezone.
 */
export function applyTimezone(time: Date, timezoneName: string): Date {
  const timezone = getTimezoneFromName(time, timezoneName);

  // The `Date` type is used in the SDK, but dates in javascript don't support timezones
  // Todo: Fix this when a better type is used in the SDK.
  return momentZone
    .parseZone(time)
    .utcOffset(timezone.timeValue, true)
    .toISOString(true) as any as Date;
}

/**
 * Utility function to get date represented in the events timezone.
 * Used now for the primevue calendar since it expects a js Date.
 */
export function changeTimezone(date: Date, timezoneName: string): Date {
  return momentZone(date).tz(timezoneName).toDate();
}
