<template>
  <svg
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.01641 0C1.51758 0 0.316406 1.26444 0.316406 2.76564V15.2249C0.316406 16.7261 1.51758 17.9999 3.01641 17.9999H22.8164C24.3152 17.9999 25.5164 16.7261 25.5164 15.2249V2.76564C25.5164 1.26447 24.3152 0 22.8164 0H3.01641ZM3.14766 1.8H22.6945L13.4509 10.575C13.1145 10.8949 12.7384 10.8949 12.4009 10.575L3.14766 1.8ZM2.11641 3.3L8.24781 9.1125L2.11641 15.225V3.3ZM23.7164 3.3V15.225L17.6039 9.1032L23.7164 3.3ZM16.31 10.3407L22.1507 16.2H3.69141L9.5507 10.35L11.1632 11.8781C12.1405 12.8051 13.7109 12.8063 14.6882 11.8781L16.31 10.3407Z"
      :fill="getColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'MailIconLight',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    getColor() {
      if (this.color) {
        return this.color;
      }
      if (this.disabled) {
        return this.$whitelabel.lightTheme.textPrimary.lighten4;
      }
      return this.$whitelabel.lightTheme.buttonPrimary.lighten4;
    }
  }
};
</script>
