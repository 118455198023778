// TODO: Add proper types
export const state = () => ({
  validSteps: [],
  user: null,
  userNotFound: false,
  userDeleted: false,
  organizerData: null,
  currencies: [],
  feeType: null,
  feeData: null,
  cancelModal: false
});

export const getters = {
  getSteps: (state) => {
    return state.validSteps;
  },
  getModal: (state) => {
    return state.cancelModal;
  },
  getUser: (state) => {
    return state.user;
  },
  getUserState: (state) => {
    return state.userNotFound;
  },
  getUserDeleted: (state) => {
    return state.userDeleted;
  },
  getOrganizerData: (state) => {
    return state.organizerData;
  },
  getCurrencies: (state) => {
    return state.currencies;
  },
  getFeeType: (state) => {
    return state.feeType;
  },
  getFeeData: (state) => {
    return state.feeData;
  }
};

export const mutations = {
  addStepValid(state, payload) {
    if (state.validSteps.includes(payload)) {
      return;
    }
    state.validSteps.push(payload);
  },
  removeStepValid(state, payload) {
    const index = state.validSteps.indexOf(payload);
    if (index > -1) {
      state.validSteps.splice(index, 1);
    }
  },
  setSteps(state, payload) {
    state.validSteps = payload;
  },
  setModal(state, payload) {
    state.cancelModal = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setUserNotFound(state, payload) {
    state.userNotFound = payload;
  },
  setUserDeleted(state, payload) {
    state.userDeleted = payload;
  },
  setCurrencies(state, payload) {
    state.currencies = payload;
  },
  setOrganizerData(state, payload) {
    state.organizerData = payload;
  },
  setFeeType(state, payload) {
    state.feeType = payload;
  },
  setFeeData(state, payload) {
    state.feeData = payload;
  }
};

export const actions: any = {
  resetUserStatus({ commit }) {
    commit('setUserNotFound', false);
    commit('setUserDeleted', false);
  },
  setUser({ commit }, payload) {
    commit('setUser', payload);
  },
  setUserNotFound({ commit }, payload) {
    commit('setUserNotFound', payload);
  },
  setUserDeleted({ commit }, payload) {
    commit('setUserDeleted', payload);
  },
  clearUser({ commit }) {
    commit('setUser', null);
    commit('setSteps', []);
  },
  clearUserState({ commit }) {
    commit('setUserNotFound', false);
    commit('setUserDeleted', false);
    commit('setSteps', []);
    commit('setUser', null);
    commit('setModal', false);
    commit('setOrganizerData', null);
    commit('setFeeType', null);
    commit('setFeeData', null);
  },
  addStepValid({ commit }, payload) {
    commit('addStepValid', payload);
  },
  removeStepValid({ commit }, payload) {
    commit('removeStepValid', payload);
  },
  setModal({ commit }, payload) {
    commit('setModal', payload);
  },
  setOrganizerData({ commit }, payload) {
    commit('setOrganizerData', payload);
  },
  setFeeType({ commit }, payload) {
    commit('setFeeType', payload);
  },
  setFeeData({ commit }, payload) {
    commit('setFeeData', payload);
  },
  setCurrencies({ commit }, payload) {
    commit('setCurrencies', payload);
  }
};
