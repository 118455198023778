<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
    <path d="M9 0.75L4.5 5.25L0 0.750001L9 0.75Z" :fill="`${color}`" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--v-textPrimary-base)'
    }
  }
}
</script>
