<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
    <path d="M0 4.75L4.5 0.25L9 4.75L0 4.75Z" :fill="`${color}`" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--v-textPrimary-base)'
    }
  }
}
</script>