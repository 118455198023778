
import Vue from 'vue';

function getPositionClass(modifiers) {
  if (modifiers.top) {
    return 'top';
  } else {
    return 'bottom';
  }
}

function TooltipDirective(el, binding) {
  setTimeout(() => {
    if (binding.value) {
      el.setAttribute('data-tooltip', binding.value?.text || binding.value);
      el.classList.add('with-tooltip');

      const position = binding.value.position || getPositionClass(binding.modifiers);
      el.classList.add(`tooltip--${position}`);
    }
  }, 100);
}

Vue.directive('tooltip', TooltipDirective);