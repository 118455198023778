<template>
  <div>
    <component :is="layoutComponent" />
  </div>
</template>

<script>
import organizerLayout from './organizer.vue';
import defaultLayout from './default.vue';

export default {
  computed: {
    layoutComponent() {
      return this.isMobile ? defaultLayout : organizerLayout;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>