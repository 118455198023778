import { User } from '@bam/sdk';

type SendNFTState = {
  user: User;
};

export const state = () =>
  ({
    user: null
  }) as SendNFTState;

export const mutations = {
  setUser(state: SendNFTState, payload: User) {
    state.user = payload;
  }
};

export const getters = {
  getUser: (state: SendNFTState) => {
    return state.user;
  }
};

export const actions: any = {
  clearUser({ commit }) {
    commit('setUser', null);
  }
};
