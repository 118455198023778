<template>
  <div class="auth__wrapper">
    <div class="auth">
      <div class="auth__aside">
        <img :src="require('assets/img/auth.png')" alt="Auth" />
        <div class="auth__aside-overlay">
          <h2 class="auth__aside-title">{{ $t('main.welcome') }}</h2>
          <p class="auth__aside-desc">
            {{ $t('main.slogan') }}
          </p>
        </div>
      </div>
      <div class="auth__content">
        <div class="auth__header">
          <div class="auth__close" @click="close()">
            <BIcon icon="cancel" />
          </div>
        </div>
        <div class="auth__container-wrapper">
          <img
            class="auth__container-logo"
            src="~/assets/img/logo_primary.svg"
            alt="Logo"
          />
          <BLogin
            v-show="currentScreen === 'login'"
            @gotoRegister="gotoRegister()"
            @gotoForgot="gotoForgot()"
          />
          <BRegister
            v-show="currentScreen === 'register'"
            @gotoLogin="gotoLogin()"
          />
          <BForgotPassword
            v-show="currentScreen === 'forgot'"
            @gotoLogin="gotoLogin()"
          />
        </div>
        <div class="auth__footer">
          <p>© {{ $t('main.companyName') }} {{ new Date().getFullYear() }}</p>
          <p>
            <!-- <BIcon icon="email" />
            <a href="mailto:help@mail.com">help@mail.com</a> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BRegister from './BRegister.vue';
import BForgotPassword from './BForgotPassword.vue';
import BIcon from '@/components/general/BIcon.vue';
import BLogin from '@/components/general/Auth/BLogin.vue';

export default {
  name: 'BAuth',
  components: {
    BIcon,
    BLogin,
    BRegister,
    BForgotPassword
  },
  data() {
    return {
      currentScreen: 'login'
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    gotoRegister() {
      this.currentScreen = 'register';
    },
    gotoLogin() {
      this.currentScreen = 'login';
    },
    gotoForgot() {
      this.currentScreen = 'forgot';
    }
  }
};
</script>
