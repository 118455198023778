import '@mdi/font/css/materialdesignicons.css';

import config from 'config';
import ChevronDown from '~/components/common/icons/ChevronDown.vue';
import ChevronUp from '~/components/common/icons/ChevronUp.vue';
import Search from '~/components/common/icons/Search.vue';
import Cart from '~/components/common/icons/Cart.vue';
import Alert from '~/components/common/icons/Alert.vue';
import Pencil from '~/components/common/icons/Pencil.vue';
import Calendar from '~/components/common/icons/Calendar.vue';
import Mail from '~/components/common/icons/Mail.vue';
import Share from '~/components/common/icons/Share.vue';
import Facebook from '~/components/common/icons/Facebook.vue';
import Twitter from '~/components/common/icons/Twitter.vue';
import CalendarFilled from '~/components/common/icons/CalendarFilled.vue';
import Download from '~/components/common/icons/Download.vue';
import Success from '~/components/common/icons/Success.vue';
import ClearBlank from '~/components/common/icons/ClearBlank.vue';

const ICONS = {
  chevronDown: {
    component: ChevronDown
  },
  chevronUp: {
    component: ChevronUp
  },
  search: {
    component: Search
  },
  cart: {
    component: Cart
  },
  alert: {
    component: Alert
  },
  pencil: {
    component: Pencil
  },
  calendar: {
    component: Calendar
  },
  mail: {
    component: Mail
  },
  share: {
    component: Share
  },
  facebook: {
    component: Facebook
  },
  twitter: {
    component: Twitter
  },
  calendarFilled: {
    component: CalendarFilled
  },
  download: {
    component: Download
  },
  success: {
    component: Success
  },
  clearBlank: {
    component: ClearBlank
  }
};
export default {
  theme: {
    dark: false,
    light: true,
    themes: {
      dark: config.darkTheme,
      light: config.lightTheme
    },
    options: {
      customProperties: true
    }
  },
  // font: {
  //   family: 'Inter'
  // },
  icons: {
    values: ICONS
  }
};
