import BigNumber from 'bignumber.js';
import { TicketDiscount } from '@bam/sdk';
import { DISCOUNT_TYPES } from './utils';

/**
 * @param {TicketDiscount} discount ticket discount
 * @param {number} price regular price
 */
export const applyDiscount = (discount: TicketDiscount, price: number) => {
  const priceBN = BigNumber(price);
  let result = priceBN;

  switch (discount.type) {
    case DISCOUNT_TYPES.AMOUNT: {
      result = priceBN.minus(discount.value);
      break;
    }
    case DISCOUNT_TYPES.PERCENTAGE: {
      result = priceBN
        .multipliedBy(BigNumber(100).minus(discount.value))
        .dividedBy(100);
      break;
    }
  }
  // Don't go below 0
  result = result.gte(0) ? result : BigNumber(0);
  return result.toNumber();
};

const choosePrice = (type: string, price: number, bestPrice: number) =>
  type === DISCOUNT_TYPES.PERCENTAGE ? bestPrice : price;

/**
 * apply discount to price and return smaller
 *
 * @param {number} price
 */
const discountReducer =
  (price: number) => (bestPrice: number, td: TicketDiscount) => {
    const discountedPrice = applyDiscount(
      td,
      choosePrice(td.type, price, bestPrice)
    );
    if (discountedPrice < bestPrice) {
      // is best price
      return discountedPrice;
    }

    return bestPrice;
  };

export const calculateOrderItemPrice = (
  price: number,
  quantity: number,
  tds: TicketDiscount[]
) => {
  if (tds == null || tds.length === 0) {
    return price * quantity;
  }
  return tds.reduce(discountReducer(price), price) * quantity;
};
