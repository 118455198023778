<!-- 
  For more info visit the following link:
  https://developers.facebook.com/docs/facebook-login/web/login-button#login-button
 -->

<template>
  <div class="facebook-login" @click="loginWithFacebook">
    <FacebookSSO />
  </div>
</template>

<script>
import FacebookSSO from '~/components/common/icons/FacebookSSO.vue';
export default {
  /* global FB */ // This tells ESLint that FB is a global variable
  components: {
    FacebookSSO
  },

  mounted() {
    this.loadFacebookSDK();
  },
  methods: {
    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        FB.init({
          appId: this.$config.facebookSSOClientID,
          cookie: true,
          xfbml: true,
          version: 'v19.0'
        });
        FB.AppEvents.logPageView();
      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    loginWithFacebook() {
      FB.login(
        (response) => {
          if (response.authResponse) {
            this.$emit('login-success', response?.authResponse?.accessToken);
          } else {
            this.$notifier.showMessage({
              content: 'User cancelled login or did not fully authorize.',
              type: 'error'
            });
          }
        },
        { scope: 'email,public_profile' }
      );
    }
  }
};
</script>
<style scoped>
.facebook-login {
  cursor: pointer;
}
</style>
