import BamColors from '@bam/whitelabel-sdk/dist/colors/bam';

const whitelabel = {
  darkTheme: {
    primary: {
      base: '#F5F6F7',
      lighten4: '#9BA8B8',
      lighten3: '#657D99',
      lighten2: '#40546c',
      lighten1: '#29384A'
    },
    secondary: {
      base: '#e94b4f',
      lighten5: '#eaebed'
    },
    error: '#FF0000',
    warning: '#F69966',
    success: {
      base: '#99DAB6',
      lighten1: '#cde1d3',
      darken1: '#8DBAA1'
    },
    accent: '#29384a',
    BAMGray: {
      base: '#fff',
      lighten1: '#f9f9f9',
      lighten2: '#f1f1f1',
      darken1: '#ECEDEF',
      darken4: '#79828D',
      darken5: '#5a6a7c'
    }
  },
  lightTheme: {
    textPrimary: {
      base: BamColors.Text.Primary[100], // Text.Primary.100
      lighten1: BamColors.Text.Primary[80], // Text.Primary.80
      lighten2: BamColors.Text.Primary[60], // Text.Primary.60
      lighten3: BamColors.Text.Primary[40], // Text.Primary.40
      lighten4: BamColors.Text.Primary[20], // Text.Primary.20
      lighten5: BamColors.Text.Primary.Invers // Text.Primary.Invers
    },
    textGray: {
      base: BamColors.Text.Gray[80], // Text.Gray.80
      lighten1: BamColors.Text.Gray[50], // Text.Gray.50
      lighten2: BamColors.Text.Gray[30] // Text.Gray.30
    },
    textHighlightError: {
      base: BamColors.Text.Highlight.Error // Text.Highlight.Error
    },
    textHighlightWarning: {
      base: BamColors.Text.Highlight.Warning // Text.Highlight.Warning
    },
    textHighlightSuccess: {
      base: BamColors.Text.Highlight.Success // Text.Highlight.Success
    },
    textHighlightInfo: {
      base: BamColors.Text.Highlight.Info // Text.Highlight.Info
    },
    backgroundPrimary: {
      base: BamColors.Background.Primary[100], // Background.Primary.100
      lighten1: BamColors.Background.Primary[80], // Background.Primary.80
      lighten2: BamColors.Background.Primary[60], // Background.Primary.60
      lighten3: BamColors.Background.Primary[40], // Background.Primary.40
      lighten4: BamColors.Background.Primary[20], // Background.Primary.20
      lighten5: BamColors.Background.Primary.Invers // Background.Primary.Invers
    },
    backgroundGray: {
      base: BamColors.Background.Gray[100], // Background.Gray.100
      lighten1: BamColors.Background.Gray[80], // Background.Gray.80
      lighten2: BamColors.Background.Gray[50], // Background.Gray.50
      lighten3: BamColors.Background.Gray[30] // Background.Gray.30
    },
    backgroundOverlay: {
      base: BamColors.Background.Overlay.White_85 // Background.Overlay.White 85; but i have manually to set the opacity in code
    },
    backgroundHighlightError: {
      base: BamColors.Background.Highlight.Error // Background.Highlight.Error
    },
    backgroundHighlightWarning: {
      base: BamColors.Text.Highlight.Warning // Background.Highlight.Warning
    },
    backgroundHighlightSuccess: {
      base: BamColors.Text.Highlight.Success // Background.Highlight.Success
    },
    backgroundHighlightTransparentError: {
      base: BamColors.Background.Highlight.Lighten2.Error // Background.Highlight.Transparent.Error
    },
    borderPrimary: {
      base: BamColors.Border.Primary[100], // Border.Primary.100
      lighten1: BamColors.Border.Primary[80], // Border.Primary.80
      lighten2: BamColors.Border.Primary[60], // Border.Primary.60
      lighten3: BamColors.Border.Primary[40], // Border.Primary.40
      lighten4: BamColors.Border.Primary[20] // Border.Primary.20
    },
    borderHighlightError: {
      base: BamColors.Border.Highlight.Error // Border.Highlight.Error
    },
    borderHighlightWarning: {
      base: BamColors.Border.Highlight.Warning // Border.Highlight.Warning
    },
    borderHighlightSuccess: {
      base: BamColors.Border.Highlight.Success // Border.Highlight.Success
    },
    borderHighlightInfo: {
      base: BamColors.Border.Highlight.Info // Border.Highlight.Info
    },
    buttonPrimary: {
      base: BamColors.Button.Primary[100], // Button.Primary.100
      lighten1: BamColors.Button.Primary[80], // Button.Primary.80
      lighten2: BamColors.Button.Primary[60], // Button.Primary.60
      lighten3: BamColors.Button.Primary[40], // Button.Primary.40
      lighten4: BamColors.Button.Primary[20] // Button.Primary.20
    },
    buttonGray: {
      base: BamColors.Button.Gray[80], // Button.Gray.80
      lighten1: BamColors.Button.Gray[50] // Button.Gray.50
    },
    buttonHighlightError: {
      base: BamColors.Button.Highlight.Error // Button.Highlight.Error
    },
    buttonHighlightTransparentError: {
      base: BamColors.Button.Highlight.Transparent.Error // Button.Highlight.Transparent.Error
    },
    buttonHighlightErrorFilled: {
      base: '#C73D39'
    }
  },
  // List of supported currencies for ticket configurations
  supportedCurrencies: [
    'EUR',
    'USD',
    'BTC',
    'XBT',
    'ETH',
    'DAI',
    'LTC',
    'BCH',
    'SAR'
  ],
  // List of supported crypto currencies
  supportedCryptoCurrencies: ['BTC', 'XBT', 'ETH', 'DAI', 'LTC', 'BCH'],

  // List of feature flags for displaying/hiding or enabling/disabling
  flags: {
    footerLogos: true
  },
  // List of URLs or links to documents and resources
  links: {
    website: 'https://bam.fan',
    // List of links in the footer
    legalNotice: 'https://www.bam.fan/legal-notice/',
    termsOfService: 'https://www.bam.fan/terms-of-service/',
    dataPrivacy: 'https://www.bam.fan/privacy-notice/',
    FAQAndSupport: 'https://bam-ticketing.freshdesk.com/support/home',
    // List of enrollment  links
    playStoreApp:
      'https://play.google.com/store/apps/details?id=com.bam.ticketwallet&hl=en_US&gl=US',
    appStoreApp:
      'https://apps.apple.com/in/app/b-a-m-wallet/id1573763381?ign-mpt=uo%3D2',
  }
};

export default whitelabel;
export type Whitelabel = typeof whitelabel;
