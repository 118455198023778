import Vue from 'vue';
import config from 'config';

export default ({ app }, inject) => {
  inject('whitelabel', Vue.observable(config));

  /**
   * Set page-title and description based on translated & whitelabeled text
   */
  app.head.title = app.i18n.t('page.title');
  app.head.meta.push({
    hid: 'description',
    name: 'description',
    content: app.i18n.t('page.description')
  });
};
