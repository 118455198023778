<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M15.5253 6.14302C15.5587 6.65135 15.517 7.22635 15.417 7.86802L14.8087 11.7764C14.292 15.0347 12.7837 16.1347 9.52532 15.6264L5.61698 15.0097C4.49198 14.8347 3.62532 14.5347 2.99199 14.0847C1.78365 13.243 1.43365 11.8597 1.76699 9.72635L2.38365 5.81802C2.90032 2.55969 4.40865 1.45969 7.66698 1.96802L11.5753 2.58469C14.192 2.99302 15.417 4.05969 15.5253 6.14302Z"
      :stroke="stroke"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0829 11.4112L15.8329 15.1695C14.7912 18.3029 13.1245 19.1362 9.99121 18.0945L6.23288 16.8445C4.34121 16.2195 3.29121 15.3529 2.99121 14.0862C3.62454 14.5362 4.49121 14.8362 5.61621 15.0112L9.52454 15.6279C12.7829 16.1362 14.2912 15.0362 14.8079 11.7779L15.4162 7.86953C15.5162 7.22786 15.5579 6.65286 15.5245 6.14453C17.5162 7.20286 17.9495 8.80286 17.0829 11.4112Z"
      :stroke="stroke"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.86699 7.66953C7.6678 7.66953 8.31699 7.02034 8.31699 6.21953C8.31699 5.41872 7.6678 4.76953 6.86699 4.76953C6.06618 4.76953 5.41699 5.41872 5.41699 6.21953C5.41699 7.02034 6.06618 7.66953 6.86699 7.66953Z"
      :stroke="stroke"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.49967 12.6519L5.49391 11.0383C5.97369 10.78 6.60273 10.8975 6.95055 11.311L7.11172 11.507C7.49385 11.9612 8.19419 12.0517 8.67916 11.7094L11.2654 9.88604C11.7503 9.54379 12.4507 9.63421 12.8328 10.0884L14.4262 11.7094"
      :stroke="stroke"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconClipboard',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 20
    }
  },
  computed: {
    stroke() {
      return this.dark
        ? this.$whitelabel.lightTheme.backgroundPrimary.base
        : this.$whitelabel.lightTheme.backgroundPrimary.lighten5;
    }
  }
};
</script>
