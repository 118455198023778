var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input",class:{
    'input--filled': _vm.isFilled,
    'input--error': _vm.errorOccurred || _vm.error,
    'input--disabled': _vm.disabled,
    'input--focused': _vm.focus,
    'input--text-right': _vm.rightOrientation,
    'input--compact': _vm.compact,
    'input--date': _vm.type === 'date',
    'input--time': _vm.type === 'time'
  }},[_c('div',{staticClass:"input__wrapper",class:{
      'input__wrapper--icon-left': _vm.leftIcon,
      'input__wrapper--icon-right': _vm.rightIcon,
      'input__wrapper--text-right': _vm.rightOrientation
    },on:{"click":function($event){return _vm.onClick()}}},[(_vm.label)?_c('label',{staticClass:"input__label"},[_vm._v(_vm._s(_vm.label + (_vm.required ? ' *' : '')))]):_vm._e(),_vm._v(" "),((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input__field",class:{
        'input__field--filled': _vm.isFilled,
        'input__field--text-right': _vm.rightOrientation
      },attrs:{"tabindex":"0","disabled":_vm.disabled,"maxlength":_vm.maximumInputLength,"max":_vm.maxValue,"min":_vm.minValue,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"blur":function($event){return _vm.onBlur($event)},"focus":function($event){return _vm.onFocus($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterKey.apply(null, arguments)},_vm.onKeyUp],"paste":function($event){return _vm.onPaste($event)},"focusout":function($event){return _vm.onFocusOut($event)},"input":function($event){return _vm.onInput($event)},"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input__field",class:{
        'input__field--filled': _vm.isFilled,
        'input__field--text-right': _vm.rightOrientation
      },attrs:{"tabindex":"0","disabled":_vm.disabled,"maxlength":_vm.maximumInputLength,"max":_vm.maxValue,"min":_vm.minValue,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"blur":function($event){return _vm.onBlur($event)},"focus":function($event){return _vm.onFocus($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterKey.apply(null, arguments)},_vm.onKeyUp],"paste":function($event){return _vm.onPaste($event)},"focusout":function($event){return _vm.onFocusOut($event)},"input":function($event){return _vm.onInput($event)},"change":function($event){_vm.model=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input__field",class:{
        'input__field--filled': _vm.isFilled,
        'input__field--text-right': _vm.rightOrientation
      },attrs:{"tabindex":"0","disabled":_vm.disabled,"maxlength":_vm.maximumInputLength,"max":_vm.maxValue,"min":_vm.minValue,"type":_vm.inputType},domProps:{"value":(_vm.model)},on:{"blur":function($event){return _vm.onBlur($event)},"focus":function($event){return _vm.onFocus($event)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterKey.apply(null, arguments)},_vm.onKeyUp],"paste":function($event){return _vm.onPaste($event)},"focusout":function($event){return _vm.onFocusOut($event)},"input":[function($event){if($event.target.composing)return;_vm.model=$event.target.value},function($event){return _vm.onInput($event)}]}}),_vm._v(" "),(_vm.rightIcon && _vm.typePassword)?[_c('transition',{attrs:{"name":"fade"}},[(_vm.inputType === 'password')?_c('IconEye',{staticClass:"input__icon input__icon--right input__icon--clickable",nativeOn:{"click":function($event){return _vm.toggleType(_vm.inputType)}}}):_c('IconEyeSlash',{staticClass:"input__icon input__icon--right input__icon--clickable",nativeOn:{"click":function($event){return _vm.toggleType(_vm.inputType)}}})],1)]:(_vm.rightIcon)?_c('b-icon',{staticClass:"input__icon input__icon--right",attrs:{"icon":_vm.rightIcon}}):_vm._e(),_vm._v(" "),(_vm.leftIcon)?_c('b-icon',{staticClass:"input__icon input__icon--left",attrs:{"icon":_vm.leftIcon}}):_vm._e(),_vm._v(" "),(_vm.type === 'date')?_c('div',{staticClass:"input__type"},[_c('IconDate')],1):_vm._e(),_vm._v(" "),(_vm.type === 'time')?_c('div',{staticClass:"input__type"},[_c('IconTime')],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"input__info-text",class:{
      'input__info-text--clickable': _vm.infoTextClickable,
      'input__info-text--right': _vm.rightInfo
    }},[_c('span',{on:{"click":_vm.infoMessageClicked}},[_vm._v(_vm._s(_vm.infoMessage ? _vm.infoMessage : ''))]),_vm._v(" "),(_vm.counterValue)?_c('span',[_vm._v(_vm._s(_vm.model.length)+" / "+_vm._s(_vm.counterValue))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }