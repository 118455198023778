import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app, $config }) => {
	Vue.use(
		VueGtag,
		{
			bootstrap: false, // Do not automatically start the tracking
			config: {
				id: $config.gtagId,
				params: {
					anonymize_ip: true,
					send_page_view: true, // Track page views automatically
				},
			},
		},
		app.router
	);
};