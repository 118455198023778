const middleware = {}

middleware['my-events-guard'] = require('../middleware/my-events-guard.js')
middleware['my-events-guard'] = middleware['my-events-guard'].default || middleware['my-events-guard']

middleware['organizer-guard'] = require('../middleware/organizer-guard.js')
middleware['organizer-guard'] = middleware['organizer-guard'].default || middleware['organizer-guard']

middleware['users-guard'] = require('../middleware/users-guard.js')
middleware['users-guard'] = middleware['users-guard'].default || middleware['users-guard']

export default middleware
