<template>
  <div class="loader" data-cy="loader">
    <div class="loader__container">
      <div class="loader__spinner"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BLoader'
};
</script>
<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  &__container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    // radial gradient semi dark transparent
    background: radial-gradient(
      circle,
      rgba(0, 43, 73, 0.5) 0%,
      rgba(0, 43, 73, 0.9) 100%
    );
  }
  &__spinner {
    width: 60px;
    height: 60px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--v-backgroundPrimary-base);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
