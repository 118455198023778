<!-- 
  For more info visit the following link:
  https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
-->

<template>
  <div
    id="appleid-signin"
    data-color="white"
    data-mode="logo-only"
    data-border="false"
    data-type="sign in"
    data-border-radius="10"
    data-size="38"
    @click="signInWithApple"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      scriptUrl:
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      scriptLoaded: false,
      listenersAttached: false
    };
  },
  mounted() {
    this.loadAppleScript();
  },
  methods: {
    loadAppleScript() {
      if (window.AppleID && this.scriptLoaded) {
        // Script already loaded and initialized
        return;
      }
      const script = document.createElement('script');
      script.src = this.scriptUrl;
      script.onload = () => {
        this.initializeAppleSignIn();
        this.scriptLoaded = true;
      };
      document.head.appendChild(script);
    },
    initializeAppleSignIn() {
      if (!this.listenersAttached) {
        try {
          window.AppleID.auth.init({
            clientId: this.$config.appleSSOClientID,
            scope: 'email name',
            redirectURI: this.$config.appleSSORedirectURI,
            state: new Date().toString(),
            usePopup: true // or false if you want a redirect
          });
        } catch (error) {
          this.$notifier.showMessage({
            content: `'Error initializing Apple Sign In: ${error}'`,
            type: 'error'
          });
        }

        document.addEventListener('AppleIDSignInOnSuccess', this.handleSuccess);
        document.addEventListener('AppleIDSignInOnFailure', this.handleError);
        this.listenersAttached = true;
      }
    },
    handleSuccess(event) {
      this.$emit('login-success', event?.detail);
    },
    handleError(event) {
      if (
        event?.detail.error === 'user_trigger_new_signin_flow' ||
        event?.detail.error === 'popup_closed_by_user'
      ) {
        return;
      }
      this.$emit('login-failure', event?.detail.error);
    },
    signInWithApple() {
      // This method will be triggered when the user clicks the sign-in button
      if (!window.AppleID) {
        this.$notifier.showMessage({
          content: 'AppleID script not loaded yet.',
          type: 'error'
        });
        return;
      }
      window.AppleID.auth.signIn();
    },
    beforeDestroy() {
      // Remove event listeners if they were added
      if (this.listenersAttached) {
        document.removeEventListener(
          'AppleIDSignInOnSuccess',
          this.handleSuccess
        );
        document.removeEventListener(
          'AppleIDSignInOnFailure',
          this.handleError
        );
      }
    }
  }
};
</script>

<style scoped>
#appleid-signin {
  cursor: pointer;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid var(--v-borderPrimary-lighten4);
  border-radius: 4px;
}
</style>
