// TODO: Add proper types
export const state = () => ({
  title: '',
  content: '',
  type: 'success'
});

export const mutations = {
  showMessage(state, payload) {
    state.title = payload.title;
    state.content = payload.content;
    state.type = payload.type;
    state.timeout = payload.timeout;
  }
};
