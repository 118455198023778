import Vue from 'vue';
import VueSanitize from 'vue-sanitize';
const defaultOptions = {
  ...VueSanitize.defaults
};
defaultOptions.allowedTags.push('img');
defaultOptions.allowedAttributes.img = ['src', 'height', 'width'];
defaultOptions.allowedAttributes.span = ['style', 'class'];
defaultOptions.allowedSchemesByTag = { img: ['data'] };
defaultOptions.allowedClasses = {
  span: ['ql-size-small', 'ql-size-large', 'ql-size-huge', 'ql-syntax']
};
defaultOptions.allowedStyles = {
  span: {
    color: [/^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
  }
};
Vue.use(VueSanitize, defaultOptions);
